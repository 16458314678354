import React, { Component } from "react";

import Profile from "./Profile";

class MySpace extends Component {
  render() {
    return <Profile />;
  }
}

export default MySpace;
